import { cn } from '@lemonenergy/lemonpie-3'

import Footer from './Footer'
import Header from './Header'

const Layout: React.FC<{
  children: React.ReactNode
  partner?: { name: string; logo: string }
  headerCTA?: string
  footerCTA?: string
  isHeaderHidden?: boolean
}> = ({ children, partner, headerCTA, footerCTA, isHeaderHidden }) => {
  return (
    <div
      className={cn(
        'flex flex-col flex-1 h-full',
        isHeaderHidden ? 'pt-none' : 'pt-[88px]',
      )}
    >
      {!isHeaderHidden && <Header partner={partner} headerCTA={headerCTA} />}
      <main className="flex-grow-1 flex-shrink-0 flex flex-col">
        {children}
      </main>
      <Footer footerCTA={footerCTA} />
    </div>
  )
}

export default Layout
